<template>
    <div ref="main-container">
        <div>
            <b-row class="mb-2">
               <b-col
                   cols="12"
                   md="4"
                   sm="12"
               >
                   <h1 class="mb-0">{{i18nT(`Locations`)}}</h1>
               </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-form-group
                    >
                        <b-form-radio-group
                            v-model="listMode"
                            button-variant="outline-secondary"
                            name="radio-btn-outline"
                            buttons
                            class="ren-active-buttons"
                        >
                            <b-form-radio value="active"  >
                                {{ i18nT(`Actifs`)}}
                                <b-badge :variant="listMode == 'active' ? 'light-primary' : 'light-secondary' " class="ml-50 text-success">{{activeTenancyCount}}</b-badge>
                            </b-form-radio>
                            <b-form-radio value="archive" >{{i18nT(`Archives`)}}
                                <b-badge class="ml-50 text-success" :variant="listMode == 'archive' ? 'light-primary' : 'light-secondary'">{{archiveTenancyCount}}</b-badge>
                            </b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-dropdown
                        variant="primary"
                        class="float-right pl-1 pb-1"
                    >
                        <template #button-content>
                            <RtIcon variant="light" icon="plus-circle mr-50" />
                            {{ i18nT(`Nouvelle location`) }}
                        </template>

                        <b-dropdown-item class="text-primary"
                                         :to="{ name: 'create-lease', }"
                        >
                            <div class=''>
                                <RtIcon variant="light" icon="plus-circle mr-50" />
                                {{ i18nT(`Nouvelle location`) }}
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item
                            :to="{name: 'import-tenants'}"
                        >
                            <div>
                                <RtIcon variant="light" icon="file-import mr-50" />
                                {{ i18nT(`Importer`) }}
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>

            <!--                    INFO CARDS-->

            <b-row class="mt-1">
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-card no-body>
                        <b-card-body class="px-1 py-1 align-items-center">
                            <div class="d-flex">
                                <b-avatar size="4em" :variant="listMode=='active' ? 'light-success' : 'light-secondary'">
                                    <RtIcon variant="light" icon="key" :size="20"/>
                                </b-avatar>
                                <div class="pl-1">
                                    <h6>{{ listMode=='active' ? i18nT(`ACTIVES`) : i18nT(`ARCHIVES`) }}
                                        <span v-if="stats.inactive && listMode=='active'">/ {{i18nT(`INACTIVES`)}}</span>
                                    </h6>
                                    <h2
                                        class="m-0"
                                        :class="listMode=='active' ? 'text-success' : 'text-secondary'"
                                    >
                                        {{ listMode=='active' ? stats.active : stats.inactive }}
                                        <span class="text-secondary" v-if="listMode=='active' && stats.inactive"> / {{stats.inactive}}</span>
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-card no-body>
                        <b-card-body class="px-1 py-1 align-items-center">
                            <div class="d-flex">
                                <b-avatar size="4em" variant="light-warning">
                                    <RtIcon variant="light" icon="coins" :size="20"/>
                                </b-avatar>
                                <div class="pl-1">
                                    <h6>{{ i18nT(`LOYERS`) }}</h6>
                                    <h2
                                        class="m-0 text-success"
                                    >
                                        {{ {amount: stats.rent } | currency }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-card no-body>
                        <b-card-body class="px-1 py-1 align-items-center">
                            <div class="d-flex">
                                <b-avatar size="4em" variant="light-warning">
                                    <RtIcon variant="light" icon="hand-holding-usd" :size="20"/>
                                </b-avatar>
                                <div class="pl-1">
                                    <h6>{{ i18nT(`DÉPÔTS DE GARANTIE`) }}</h6>
                                    <h2
                                        class="m-0 text-success"
                                    >
                                        {{ {amount: stats.deposit } | currency }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-card no-body>
                        <b-card-body class="px-1 py-1 align-items-center">
                            <div class="d-flex">
                                <b-avatar size="4em" variant="light-danger">
                                    <RtIcon variant="light" icon="alarm-exclamation" :size="20"/>
                                </b-avatar>
                                <div class="pl-1">
                                    <h6>{{ i18nT(`LOYERS IMPAYÉS`) }}</h6>
                                    <h2
                                        class="m-0 text-danger"
                                    >
                                        {{stats['rent_late_count']}}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-card no-body>
                        <b-card-body class="px-1 py-1 align-items-center">
                            <div class="d-flex">
                                <b-avatar size="4em" variant="light-danger">
                                    <RtIcon variant="light" icon="alarm-exclamation" :size="20"/>
                                </b-avatar>
                                <div class="pl-1">
                                    <h6>{{ i18nT(`MONTANT DES LOYERS IMPAYÉS`) }}</h6>
                                    <h2
                                        class="m-0 text-danger"
                                    >
                                        {{ {amount: stats['rent_late'] } | currency }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </div>

<!--        FILTERS-->

        <div>
            <b-card no-body>
                <div class="m-2"  ref="filters-container">
                        <b-row class>
                            <b-col
                                cols="12"
                                md="3"
                            >
                                <b-form-group :label="i18nT(`Date`)">
                                    <date-picker
                                        :id="'InvoiceDateRange'"
                                        class=""
                                        :range="true"
                                        :disabled="false"
                                        v-model="reportRange"
                                        :only-date="true"
                                        :only-time="false"
                                        :format="`YYYY-MM-DD`"
                                        :formatted="dateFormat"
                                        :color="`#5a5aff`"
                                        :label="i18nT(`Select date`)"
                                        :button-now-translation="i18nT(`Today`)"
                                        :first-day-of-week="1"
                                        :no-button="true"
                                        :auto-close="true"
                                        :ref="`reportRangePicker`"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                md="3"
                            >
                                <b-form-group :label="i18nT(`Propriétaires`)">
                                    <rt-select
                                        v-model="tenancyLandlord"
                                        :options="tenancyLandlords"
                                        label="text"
                                        :selectable="selected => selected.disabled !== true"
                                        :placeholder="i18nT(`Tous les propriétaires`)"
                                        :closeOnSelect="false"
                                        multiple
                                        deselectFromDropdown
                                        :persistentPlaceholder="['Propriétaire', 'Propriétaires']"
                                        :reduce="(e) => e.value"
                                        id='tenancyLandlord'
                                        icon="user-check"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="3"
                            >
                                <b-form-group :label="i18nT(`Bien`)">
                                    <rt-select
                                        v-model="tenancyProperty"
                                        :options="tenancyProperties"
                                        label="text"
                                        :selectable="selected => selected.disabled !== true"
                                        :placeholder="i18nT(`Tous les biens`)"
                                        :closeOnSelect="false"
                                        multiple
                                        deselectFromDropdown
                                        :persistentPlaceholder="['Bien', 'Biens']"
                                        :reduce="(e) => e.value"
                                        id='tenancyProperty'
                                        icon="building"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="3"
                            >
                                <b-form-group :label="i18nT(`Type`)">
                                    <rt-select
                                        v-model="tenancyType"
                                        :options="tenancyTypes"
                                        label="text"
                                        multiple
                                        :selectable="selected => selected.disabled !== true"
                                        :placeholder="i18nT(`Tous type`)"
                                        deselectFromDropdown
                                        :reduce="(e) => e.value"
                                        :persistentPlaceholder="['Type', 'Types']"
                                        :clearable="false"
                                        id='tenancyType'
                                        icon="file-contract"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="3"
                            >
                                <b-form-group :label="i18nT(`Bail`)">
                                    <rt-select
                                        v-model="tenancyExpiration"
                                        :options="tenancyExpirationDays"
                                        label="text"
                                        :selectable="selected => selected.disabled !== true"
                                        :placeholder="i18nT(`Fin du bail`)"
                                        deselectFromDropdown
                                        :reduce="(e) => e.value"
                                        :clearable="false"
                                        id='tenancyExpiration'
                                        icon="timer"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div>
                            <rt-all-filters @removeFilter="removeFilter" @clearFilters="clearFilters"/>
                        </div>
                    </div>
            </b-card>
        </div>

<!--        TABLE AND SEARCH-->
        <b-card no-body>
            <div>
                <b-row class="mx-2 mt-2">
                    <b-col
                        cols="12"
                        md="7"
                        sm="6"
                        class="mb-1 pl-0"
                    >
                        <v-select
                            v-model="perPage"
                            :clearable="false"
                            :options="perPageOptions"
                            class="per-page-selector d-inline-block"
                        />
                        <b-dropdown
                            v-if="selectedItems.length > 0"
                            class="d-inline ml-1"
                            size="md"
                            text="Actions"
                            variant="outline-secondary"
                        >
                            <b-dropdown-item @click="onBulkArchive()">
                                <RtIcon :size="16" variant="light" icon="box-archive"/>
                                <span class="align-middle ml-50">{{ i18nT(`Archiver la sélection`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="onBulkDelete()">
                                <span class="text-danger"><RtIcon :size="16" variant="light" icon="trash"/></span>
                                <span
                                    class="align-middle ml-50 text-danger"
                                >{{
                                        i18nT(`Supprimer`)
                                    }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>

<!--                    search-input-->
                    <b-col
                        cols="12"
                        md="5"
                        sm="6"
                        class="mb-1 pr-0 d-flex justify-content-end"
                    >
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="input-group input-group-merge" role="group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <RtIcon variant="light" icon="search"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model.lazy="searchQuery"
                                        :placeholder="i18nT(`Chercher`)"
                                        class="d-inline-block"
                                        type="text"
                                    />
                                </div>
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
                <b-table
                    ref="refTenanciesListTable"
                    v-model="currentItems"
                    :key="'TenancyTable'+ tableColumns.length"
                    :empty-html="emptyHtml"
                    :fields="computedColumns"
                    :items="fetchTenancies"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    primary-key="id"
                    responsive
                    show-empty
                    @refreshed="onTableRefresh"
                >
                    <template v-slot:head(checkbox)="data">
                        <span>{{ data.label.toUpperCase() }}</span>
                        <b-form-checkbox
                            v-model="selectAllStatus"
                            class="d-inline-block pl-0"
                            @change="onSelectAll"
                        >
                        </b-form-checkbox>
                    </template>

                    <template #head(action)="data">
                        <span>{{ data.label.toUpperCase() }}</span>
                        <div class="sk-column-control">
                            <b-dropdown
                                no-caret
                                variant="button"
                                toggle-class="p-0"
                                :right="true"
                                class="pr-1 pl-1 d-flex sk-column-filter"
                                v-if="true"
                                v-b-tooltip="i18nT(`Colonnes`)"
                            >
                                <template #button-content>
                                    <RtIcon variant="light" icon="gear text-body ren-lh-22" size="18px"/>
                                </template>

                                <div class="pl-1 pr-1" v-for="(field, index) in availableFields"
                                     :key="'Fields - ' + index">
                                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">
                                        {{ i18nT(field.label) }}
                                    </b-form-checkbox>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>

                    <template #cell(checkbox)="data">
                        <b-form-checkbox
                            v-model="selectedItems"
                            :value="data.item.LeaseID"
                            class="pl-0"
                        />
                    </template>

                    <template #cell(PropertyTitle)="data">
                        <div class="d-flex flex-column">
                            <b-link class="text-nowrap mb-0 font-weight-bold"  :to="{name: 'view-lease', params: {id: data.item.LeaseID}}">
                            <span v-b-tooltip.hover.topleft.html="tipData(data.item)">
                                {{ data.item.Properties[0].PropertyTitle }}
                                {{ `${data.item.LeaseTitle ? ' - ' + data.item.LeaseTitle : ''}` }}
                            </span>
                            </b-link>
                            <small class="text-nowrap">
                                {{ getTypeName(data.item.LeaseType)}}
                            </small>
                            <small class="text-nowrap">
                                <RtIcon variant="light" icon="location-dot"/>
                                {{ data.item.Properties[0].PropertyAddress }}
                            </small>
                            <small v-if="data.item.LeaseID%3==0">
                                <RtIcon variant="light pr-25 text-warning" icon="triangle-exclamation"/>
                                {{ i18nT(`Loyers impayés`) }}
                            </small>
                        </div>
                    </template>

                    <template #cell(LeaseType)="data">
                        <p class="m-0 text-nowrap">{{getTypeName(data.item.LeaseType)}}</p>
                    </template>

                    <template #cell(LeaseTenants)="data">
                        <div class="d-flex">
                            <b-link v-if="data.item.Tenants != null"
                                    v-for="(tenant, index) in data.item.Tenants"
                                    :key="'tenant'+index"
                                    class="mr-1 d-flex ren-tenant cursor-pointer text-nowrap"
                                    :to="{name:'view-tenant', params:{id: tenant.TenantID}}"
                            >
                                {{tenant.TenantFirstName}} {{tenant.TenantLastName}}
                            </b-link>
                            <span v-if="data.item.Tenants.length==0"> N/A </span>
                        </div>
                    </template>

                    <template #cell(LeaseMonthlyAmount)="data">
                        <h5 class="m-0 text-nowrap"
                            :class="!data.item.LeaseMonthlyAmount ? '' : parseFloat(data.item.LeaseMonthlyAmount) < 0 ? 'text-secondary' : 'text-success'"
                        >
                            {{ {amount: data.item.LeaseMonthlyAmount, currency: 'EUR' } | currency }}
                        </h5>
                    </template>

                    <template #cell(LeaseSecurityDeposit)="data">
                        <h5 class="m-0 text-nowrap"
                            :class="!data.item.LeaseSecurityDeposit ? '' : parseFloat(data.item.LeaseSecurityDeposit) < 0 ? 'text-secondary' : 'text-success'"
                        >
                            {{ {amount: data.item.LeaseSecurityDeposit, currency: 'EUR' } | currency }}
                        </h5>
                    </template>

                    <template #cell(LeaseStartDate)="data">
                        <h5 class="m-0 text-nowrap">
                            {{data.item.LeaseStartDate | date}}
                            <span v-if="data.item.LeaseEndDate"> - {{ data.item.LeaseEndDate | date }}</span>
                        </h5>
                    </template>


<!--                    TODO ADD LEASE BALANCE IN API RESPONSE-->
                    <template #cell(LeaseBalance)="data">
                        <h5 class="m-0 text-nowrap"
                            :class="!data.item.LeaseBalance? '' : parseFloat(data.item.LeaseBalance) < 0 ? 'text-danger' : 'text-success'"
                        >
                            {{ {amount: data.item.LeaseBalance, currency: 'EUR' } | currency }}
                        </h5>
                    </template>

                    <template #cell(LeaseActive)="data">
                        <b-badge
                            :variant="data.item.LeaseActive == 1 ? 'light-success' : 'light-secondary'"
                            class="badge-pill p-50 cursor-pointer"
                            v-b-tooltip="data.item.LeaseActive == 1 ? i18nT(`Le location est active`) : i18nT(`Active la location`)"
                            @click="onActivate(data.item)"
                        >
                            {{data.item.LeaseActive == 1 ? i18nT(`Active`) : i18nT(`Inactive`)}}
                        </b-badge>
                    </template>

                    <template #cell(templates)="data">
                        <b-dropdown
                            toogle-class="p-0"
                            variant="outline-secondary"
                            size="sm"
                        >
                            <template #button-content>
                                {{i18nT('Modèle')}}
                            </template>

                            <b-dropdown-item v-for="template in templates" :key="template.value" @click="openDownloadModal(template)">
                                <RtIcon variant="light" icon="file-lines mr-1 text-body"/>
                                <span>{{template.text}}</span>
                            </b-dropdown-item>
                            <hr />
                            <b-dropdown-item>
                                <RtIcon variant="light" icon="plus mr-1 text-body"/>
                                <span>{{i18nT(`Créer un inventaire`)}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <RtIcon variant="light" icon="plus mr-1 text-body"/>
                                <span>{{i18nT(`Créer un état des lieux d'entrée`)}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <RtIcon variant="light" icon="plus mr-1 text-body"/>
                                <span>{{i18nT(`Créer un état des lieux de sortie`)}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>

                    <template

                        #cell(action)="data">
                        <b-link :to="{
                                          name: 'edit-lease',
                                          params: { id: data.item.LeaseID },

                                        }"
                                class='text-secondary'
                                v-b-tooltip.hover.top="i18nT(`Modifier`)"
                        >
                            <RtIcon variant="light" icon="edit mr-1" :size="15"/>
                        </b-link>
                        <b-link :to="{name: 'view-lease', params: {id: data.item.LeaseID}}"
                                class='text-secondary'
                                v-b-tooltip.hover.top="i18nT(`Voir`)"
                        >
                            <RtIcon variant="light" icon="eye mr-1" :size="15"/>

                        </b-link>

                        <b-dropdown
                            no-caret
                            toggle-class="p-0"
                            variant="link"
                            menu-class="action-dropdown"
                            dropleft
                        >
                            <template #button-content>
                                <RtIcon variant="light" icon="ellipsis-vertical align-middle text-body ml-1" :size="18"/>
                            </template>
                            <b-dropdown-item
                                :to="{
                                          name: 'edit-lease',
                                          params: { id: data.item.LeaseID },
                                        }">
                                <RtIcon variant="light" icon="edit" />
                                <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onArchive(data.item)"
                            >
                                <RtIcon variant="light" icon="box-archive"/>
                                <span class="align-center ml-50">
                                            {{listMode === 'active' ? i18nT(`Archiver`) : i18nT(`Désarchiver`)}}
                                        </span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onActivate(data.item)"
                            >
                                <RtIcon variant="light" :icon="data.item.LeaseActive == '0' ? 'check' : 'xmark'"/>
                                <span class="align-center ml-50">
                                            {{data.item.LeaseActive == '0' ? i18nT(`Activer`) : i18nT(`Désactiver`)}}
                                        </span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onDelete(data.item)">
                                <RtIcon variant="light" icon="trash text-danger"/>
                                <span
                                    class="align-middle ml-50 text-danger"
                                >{{
                                        i18nT(`Supprimer`)
                                    }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>



                </b-table>
            </div>
            <div class="mx-1 mb-2">
                <b-row v-if="tenancies.length > 0">
                    <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        cols="12"
                        sm="4"
                    >
                        <b-form-checkbox  v-model="selectAllStatus" class="d-inline-block mx-1"
                                          @change="onSelectAll"
                        >
                            {{ i18nT(`Tout`) }}
                        </b-form-checkbox>
                        <b-dropdown
                            variant="outline-secondary"
                            size="sm"
                        >
                            <template #button-content>
                                <RtIcon variant="light" icon="download"/>
                                <span class="ml-50">{{ i18nT(`Export`) }}</span>
                            </template>

                            <b-dropdown-item class="text-primary"
                            >
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-excel"/>
                                    <span class="pl-1">
                                    {{ i18nT(`Export format Excel`) }}
                                        </span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item
                            >
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-lines"/>
                                    <span class="pl-1">{{ i18nT(`Export format OpenOffice`) }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col  cols="12" sm="4" class="d-flex align-items-center justify-content-center">
                        <span class="text-muted ml-1 d-none d-md-flex text-nowrap">
                            {{ i18nT(`Affichage de`) }} {{ dataMeta.from }} {{ i18nT(`à`) }}
                            {{ dataMeta.to }} {{ i18nT(`sur`) }} {{ dataMeta.of }}
                            {{ i18nT(`entrées`) }}</span></b-col>
                    <!-- Pagination -->
                    <b-col
                        class="
                            d-flex
                            align-items-center
                            justify-content-center justify-content-sm-end
                          "
                        cols="12"
                        sm="4"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalTenancies"
                            class="mb-0 mt-1 mt-sm-0"
                            first-number
                            last-number
                            next-class="next-item"
                            prev-class="prev-item"
                        >
                            <template #prev-text>
                                <RtIcon variant="light" icon="chevron-left" :size="16"/>
                            </template>
                            <template #next-text>
                                <RtIcon variant="light" icon="chevron-right" :size="16"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!--        FLOAT ACTIONS-->
        <b-card no-body v-if="selectedItems.length>0" class="float-actions">
            <b-row class="">
                <b-col cols="12"
                       class="d-flex m-1 "
                >
                    <span class="d-none d-md-flex align-items-center mr-1">
                    {{i18nT(`Actions`)}}
                        </span>
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Supprimer la sélection`)"
                        @click="onBulkDelete"
                    >
                        <RtIcon :size="16" variant="light" icon="trash"/>
                    </b-button>
                    <b-button
                        variant="outline-warning"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Archiver la sélection`)"
                        @click="onBulkArchive"
                        v-if="listMode === 'active'"
                    >
                        <RtIcon :size="16" variant="light" icon="box-archive"/>
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Désactiver la sélection`)"
                    >
                        <RtIcon :size="16" variant="light" icon="xmark"/>
                    </b-button>
                    <b-button
                        variant="outline-success"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "listMode === 'active' ? i18nT(`Activer la sélection`) : i18nT(`Désarchiver la sélection`)"
                    >
                        <RtIcon :size="16" variant="light" icon="check"/>
                    </b-button>
                    <b-button
                        variant="outline-info"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Envoyer un message`)"
                    >
                        <RtIcon :size="16" variant="light" icon="comment-lines"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

    </div>
</template>

<script>

import {
    BAvatar, BButton,
    BCard,
    BCardBody,
    BCol, BDropdown, BDropdownItem, BFormCheckbox,
    BFormGroup,
    BFormRadioGroup, BLink,
    BRow,
    VBTooltip,
} from "bootstrap-vue";
import useTenanciesList from "@/views/landlord/Tenancies/tableTenanciesList";
import RtSelect from "@core/components/RtSelect.vue";
import DatePicker from "@core/components/DatePicker.vue";
import useAuth from '@/auth/useAuth';
import RtAllFilters from "@core/components/RtAllFilters.vue";
import vSelect from 'vue-select'
import {dictToSelectArray} from "@core/utils/utils";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from '@/store/index'




export default {
    components: {
        BButton,
        BLink,
        BFormCheckbox,
        RtAllFilters,
        DatePicker,
        RtSelect,
        BAvatar,
        BCard,
        BCardBody,
        BDropdownItem,
        BDropdown,
        BCol,
        BFormGroup,
        BRow,
        BFormRadioGroup,
        vSelect,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },

    setup() {
        const{
            refTenanciesListTable,
            tableColumns,
            perPage,
            totalTenancies,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            tenancies,
            tenancyLandlord,
            tenancyProperty,
            tenancyType,
            listMode,
            tenancyEndDate,
            tenancyStartDate,
            tenancyExpiration,
            activeTenancyCount,
            archiveTenancyCount,
            stats,
            dataMeta,
            fetchTenancies,
            refetchData,
        } = useTenanciesList();

        return{
            refTenanciesListTable,
            tableColumns,
            perPage,
            totalTenancies,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            tenancies,
            tenancyLandlord,
            tenancyProperty,
            tenancyType,
            tenancyExpiration,
            listMode,
            tenancyEndDate,
            tenancyStartDate,
            activeTenancyCount,
            archiveTenancyCount,
            stats,
            dataMeta,
            fetchTenancies,
            refetchData,
        }

    },
    data(){
        return{
            tenancyProperties: [],
            tenancyLandlords: [],
            reportRange: {
                start: this.tenancyStartDate,
                end: this.tenancyEndDate,
            },
            tenancyTypes: [],
            tenancyExpirationDays: [],
            selectedItems: [],
            selectAllStatus: false,
            currentItems: [],
            availableFields: [
                {label: this.i18nT(`Type`), key: 'LeaseType', visible: true},
                {label: this.i18nT(`Locataire`), key: 'LeaseTenants', visible: true},
                {label: this.i18nT(`Loyer`), key: 'LeaseMonthlyAmount', visible: true},
                {label: this.i18nT(`Dépôt`), key: 'LeaseSecurityDeposit', visible: true},
                {label: this.i18nT(`Durée`), key: 'LeaseStartDate', visible: true},
                {label: this.i18nT(`Solde`), key: 'LeaseBalance', visible: true},
                {label: this.i18nT(`Etat`), key: 'LeaseActive', visible: true},
            ],
            templates: [{text:'Test Template'}]
        }
    },
    created(){
        let storePreferences = useAuth.getColumnPreferences('tenancies')
            if(storePreferences) {
                this.availableFields = storePreferences
            }

        this.getFilters();
    },
    computed: {
        dateFormat() {
            return useAuth.getDateFormat() != 'null'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
        emptyHtml() {
            let imgPath = store.state.appConfig.layout.skin === 'dark'

            return "<img src='" + require(imgPath ? '@client/images/errorDark.svg' : '@client/images/error.svg')
                + "' alt='Error page' class='img-fluid' width='300'><br/><h2>"
                + this.i18nT(`Il n'y a rien par ici ...`) + "</h2><p>"
                + this.i18nT(`Cette page permet de gérer les locations. Si vous possédez 5 biens loués à 5 locataires différents, il faudra créer 5 contrats de location.
                Pour chaque contrat de location, les loyers et les quittances sont générés automatiquement dans la rubrique Finances.`) + "</p>"
                +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/" +"leases/new/general-info\"'>" + this.i18nT(`Nouvelle location`) + "</button>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.availableFields.length; i++) {
                if(!this.availableFields[i].visible) {
                    col = col.filter(c => c.key !== this.availableFields[i].key)
                }
            }
            useAuth.setColumnPreferences('tenancies', this.availableFields);

            return col;
        },
    },
    methods: {
        getFilters(){

            const landlordFilters = this.tenancyLandlord.length > 0 ? "?LandlordID=" + this.tenancyLandlord.join(',') : ""

            this.$http.get(`landlord/leases/filters${landlordFilters}`).then(({data})=>{

                this.tenancyLandlords = dictToSelectArray(data.data.landlords)
                this.tenancyLandlords.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                })

                this.tenancyTypes = dictToSelectArray(data.data['lease_types'])
                    .filter(item => item.text)
                    .sort((a, b) => a.text.localeCompare(b.text))

                this.tenancyTypes.push(this.tenancyTypes.shift());

                this.tenancyTypes.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                })

                this.tenancyExpirationDays = dictToSelectArray(data.data['expire_in']);

                this.tenancyExpirationDays.unshift({
                    value: '-1',
                    text: this.i18nT(`Fin du bail`)
                })


                const properties = dictToSelectArray(data.data.properties)
                const buildings = dictToSelectArray(data.data.buildings)

                properties.unshift({
                    value: 0,
                    text: this.i18nT(  `-- BIENS --`),
                    disabled: true,
                });

                buildings.unshift({
                    value: 0,
                    text: this.i18nT(  `-- IMMEUBLES --`),
                    disabled: true,
                });

                this.tenancyProperties=[...properties,...buildings];
            })
        },
        onBulkDelete(){
            const idsString = this.selectedItems.join(',')

            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Confirmez la suppression des locations sélectionnés.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http.post(`landlord/leases/massDelete?ids=${idsString}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false;
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.selectedItems = []
                        this.selectAllStatus = false;
                        this.refetchData()
                    })
                }
            })
        },
        onDelete(item){
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression de la location. Cette action entrainera la suppression de tous les revenus et dépenses qui lui sont liés.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http.delete(`landlord/leases?id=${item.LeaseID}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkArchive(){
            const idsString = this.selectedItems.join(',')
            const type = this.listMode === 'active' ? 'massArchive' : 'massUnarchive'

            this.$http.post(`landlord/leases/${type}?ids=${idsString}`)
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onArchive(item){
            const type = this.listMode === 'active' ? 'archive' : 'unarchive'

            this.$http.post(`landlord/leases/${type}?id=${item.LeaseID}`)
                .then(() => {
                    this.refetchData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onBulkActivate(){
            //TODO
        },
        onBulkDeactivate(){
            //TODO
        },
        onActivate(item){
            const type = item.LeaseActive ===0 ? 'activate' : 'deactivate';
            const textString = `Vous allez ${type=='activate' ? 'activer' : 'désactiver'} cette location.`

            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(textString),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result=>{
                if(result.value){
                    this.$http.post(`landlord/leases/${type}?id=${item.LeaseID}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Sauvegardé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        }).catch(err=>{
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onTableRefresh(){
            this.selectedItems = []
        },
        clearFilters(){
            this.tenancyType = [];
            this.tenancyExpiration = '';
            this.tenancyLandlord = [];
            this.tenancyProperty = [];

        },
        removeFilter(filter) {
            const multiple = typeof(this[filter.originalArray]) == 'object'

            if(!multiple) {
                this[filter.originalArray] = ''
            } else {
                this[filter.originalArray] = this[filter.originalArray].filter(item => item !== filter.value)
            }
        },
        onSelectAll(val){
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.LeaseID)
            } else {
                this.selectedItems = []
            }
        },
        getTypeName(id){
            const type = this.tenancyTypes.find(item => item.value == id)
            return type ? type.text : ''
        },
        tipData(tenancy){

            const typeName = this.getTypeName(tenancy.LeaseType);
            const typeTitle = this.i18nT(`Type`);
            const startDate = moment(tenancy.LeaseStartDate).format(this.dateFormat);
            const endDate = tenancy.LeaseEndDate ? moment(tenancy.LeaseEndDate).format(this.dateFormat) : '';
            const duration = `${startDate}${endDate?`- ${endDate}`:''}`;
            const durationTitle = this.i18nT(`Durée`);
            const status = tenancy.LeaseActive == 1 ? this.i18nT(`Active`) : this.i18nT(`Inactive`);
            const statusClass = tenancy.LeaseActive == 1 ? 'success' : 'secondary';
            const statusTitle = this.i18nT(`Etat`);
            const renew = tenancy.LeaseFixedAutoRenew == 1 ? this.i18nT(`Oui`) : this.i18nT(`Non`);
            const renewTitle = this.i18nT(`Renouvellement`);
            const paymentDay = tenancy.LeasePaymentDay + ' ' + this.i18nT(`du mois`);
            const paymentDayTitle = this.i18nT(`Date de quittancement`);
            const paymentCreateDay = tenancy.LeasePaymentCreateDay == 0 ? this.i18nT(`Même que date de quittancement`) : 'J' + tenancy.LeasePaymentCreateDay;
            const paymentCreateDayTitle = this.i18nT(`Date de génération du loyer`);
            const nextPaymentDate = tenancy.LeaseDueDate ? moment(tenancy.LeaseDueDate).format(this.dateFormat) : null;
            const nextPaymentDateTitle = this.i18nT(`Prochain paiement`);
            //TODO - get total Rent, maybe calculate it in the backend
            const totalRent = this.$options.filters.currency({amount:tenancy.LeaseRentHC});
            const totalRentTitle = this.i18nT(`Loyer CC`);
            const maintenance = this.$options.filters.currency({amount:tenancy.LeaseMaintenance});
            const maintenanceTitle = this.i18nT(`Charges`);
            //TODO check if billing period come form backend or we need to create them in the frontend as a collection
            const billingPeriod = tenancy.LeaseBillingPeriod == 'monthly' ? this.i18nT(`Mensuel`) : '';
            const billingPeriodTitle = this.i18nT(`Paiement`);
            const paymentMethod = tenancy.LeasePaymenMethodID ? tenancy.LeasePaymenMethodID : tenancy.LeaseSoldeReport ? this.i18nT(`Automatique`) : this.i18nT(`Manuel`);
            const paymentMethodTitle = this.i18nT(`Report du solde`);

            return `
                <div class="tooltip-container">
                    <div class="tooltip-text-container">
                        ${typeName ?`<p class="tooltip-text"><b>${typeTitle}:</b>&nbsp;&nbsp;${typeName}</p>` : ''}
                        ${duration ? `<p class="tooltip-text"><b>${durationTitle}:</b>&nbsp;&nbsp;${duration}</p>` : ''}
                        ${renew ? `<p class="tooltip-text"><b>${renewTitle}:</b>&nbsp;&nbsp;${renew}</p>` : ''}
                        ${paymentDay ? `<p class="tooltip-text"><b>${paymentDayTitle}:</b>&nbsp;&nbsp;${paymentDay}</p>` : ''}
                        ${paymentCreateDay ? `<p class="tooltip-text"><b>${paymentCreateDayTitle}:</b>&nbsp;${paymentCreateDay}</p>` : ''}
                        ${nextPaymentDate ? `<p class="tooltip-text"><b>${nextPaymentDateTitle}:</b>&nbsp;${nextPaymentDate}</p>` : ''}
                        ${totalRent ? `<p class="tooltip-text"><b>${totalRentTitle}:</b>&nbsp; ${totalRent}</p>` : ''}
                        ${maintenance ? `<p class="tooltip-text"><b>${maintenanceTitle}:</b>&nbsp; ${maintenance}</p>` : ''}
                        ${billingPeriod ? `<p class="tooltip-text"><b>${billingPeriodTitle}:</b>&nbsp;${billingPeriod}</p>` : ''}
                        ${paymentMethod ? `<p class="tooltip-text"><b>${paymentMethodTitle}:</b>&nbsp;${paymentMethod}</p>` : ''}
                        <p class="tooltip-text"><b>${statusTitle}:</b> &nbsp;&nbsp;<span class="tooltip-badge bg-${statusClass}">${status}</span></p>
                    </div>
                </div>
            `;
        },
    },
    watch: {
        tenancyLandlord: {
            handler() {
                this.getFilters()
            },
            deep: true
        },
        reportRange: {
            deep: true,
            handler(newRange) {
                if(newRange) {
                    if(newRange.end) {
                        this.tenancyStartDate = moment(newRange.start).format('YYYY-MM-DD');
                        this.tenancyEndDate = newRange.end ? moment(newRange.end).format('YYYY-MM-DD') : '';
                    }
                } else {
                    this.tenancyStartDate = '';
                    this.tenancyEndDate = '';
                }

            }
        },
    },

}
</script>

<style lang="scss">
//.ren-info-card{
//    border: 1px solid #e1dee7;
//}
//
//.dark-layout .ren-info-card {
//    border: 1px solid #3b4253 !important;
//}

</style>