import axios from '@axios';
import { ref, watch, computed } from '@vue/composition-api'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
const { i18n } = useI18nUtils();
function i18nT(str){
	return i18n(str)
};

export default function useTenanciesList() {

	const refTenanciesListTable = ref(null);

	const tableColumns = [
		{key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
		{key: 'PropertyTitle', label: i18nT(`Bien`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
		{key: 'LeaseType', label: i18nT(`Type`), sortable: true },
		{key: 'LeaseTenants', label: i18nT(`Locataire`), sortable: false },
		{key: 'LeaseMonthlyAmount', label: i18nT(`Loyer`), sortable: true },
		{key: 'LeaseSecurityDeposit', label: i18nT(`Dépôt`), sortable: true },
		{key: 'LeaseStartDate', label: i18nT(`Durée`), sortable: true },
		{key: 'LeaseBalance', label: i18nT(`Solde`), sortable: true },
		{key: 'LeaseActive', label: i18nT(`Etat`), sortable: true },
		{key: 'templates', label: i18nT(`Modèles`), sortable: false },
		{
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions',
			tdClass: 'sticky-column',
			stickyColumn: true
		},
	]

	const perPage = ref(10);
	const totalTenancies = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('');
	const isSortDirDesc = ref(true);
	const tenancies = ref([]);
	const tenancyLandlord = ref([]);
	const tenancyProperty = ref([]);
	const tenancyType = ref([]);
	const listMode = ref('active');
	const tenancyStartDate = ref('');
	const tenancyEndDate = ref('');
	const tenancyExpiration = ref('');
	const activeTenancyCount = ref(0);
	const archiveTenancyCount = ref(0);
	const stats = ref({});

	const dataMeta = computed(()=> {
		const localItemsCount = refTenanciesListTable.value
			? refTenanciesListTable.value.localItems.length
			: 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalTenancies.value,
		}
	})

	let timeoutId = null;

	const refetchData = () => {
		refTenanciesListTable.value.refresh();
	}

	watch(
		[
			currentPage,
			perPage,
			tenancyProperty,
			tenancyType,
			tenancyLandlord,
			listMode,
			tenancyEndDate,
			tenancyExpiration,
		],
		() => {
			refetchData();
		}
	)

	watch (
		searchQuery,
		() => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				refetchData();
			}, 500);
		}
	)

	const fetchTenancies = (ctx, callback) => {
		const params = {
			page: currentPage.value,
			show_per_page:perPage.value,
			sort_by: sortBy.value,
			sort_oder: isSortDirDesc.value ? 'DESC' : 'ASC',
			search: searchQuery.value,
		}
		//TODO - check filter names with backend
		if(tenancyType.value.length>0 && !(tenancyType.value.includes('-1'))) {
			params['filter[LeaseType]'] = tenancyType.value.join(',');
		}
		if(tenancyLandlord.value.length>0 && !(tenancyLandlord.value.includes('-1'))) {
			params['filter[LandlordID]'] = tenancyLandlord.value.join(',');
		}
		if(tenancyProperty.value.length>0 && !(tenancyProperty.value.includes('-1'))) {
			params['filter[PropertyID]'] = tenancyProperty.value.join(',');
		}
		if(tenancyStartDate.value != '') { // TODO check date filter
			params['filter[PeriodFrom]'] = tenancyStartDate.value;
		}
		if(tenancyEndDate.value != '') { //TODO - check date format
			params['filter[PeriodTo]'] = tenancyEndDate.value;
		}
		if(tenancyExpiration.value != '') {
			params['filter[Expires]'] = tenancyExpiration.value;
		}
		if(listMode.value == 'active') {
			params['active'] = 1;
		}

		axios.get(`landlord/leases`, {
			params
		}). then(({data})=>{
			if(data.data.leases) {
				tenancies.value = data.data.leases;
				callback(data.data.leases);
			} else {
				tenancies.value = []
				callback([])
			}

			totalTenancies.value = data.data.pagination['records_count'];
			activeTenancyCount.value = data.data['records_count_active'];
			archiveTenancyCount.value = data.data['records_count_archive'];
			stats.value = data.data.stats;
		})

	}

	return {
		refTenanciesListTable,
		tableColumns,
		perPage,
		totalTenancies,
		currentPage,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		tenancies,
		tenancyLandlord,
		tenancyProperty,
		tenancyType,
		listMode,
		tenancyEndDate,
		tenancyStartDate,
		tenancyExpiration,
		activeTenancyCount,
		archiveTenancyCount,
		stats,
		dataMeta,
		fetchTenancies,
		refetchData,
	}
}